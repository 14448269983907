import React from 'react';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    Code,
    FormatQuote,
    FormatListBulleted,
    FormatListNumbered,
    FormatAlignLeft,
    FormatAlignCenter,
    FormatAlignRight,
    FormatAlignJustify,
    Link,
    Image,
    Title,
    StrikethroughS,
    FormatColorText,
    Subscript,
    Superscript,
    HorizontalRule,
    FormatClear,
} from '@mui/icons-material';

const Toolbar = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div style={{
            marginBottom: '15px',
            padding: '10px',
            background: '#f4f4f4',
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(40px, 1fr))',
            gap: '8px',
            justifyContent: 'center',
            gridTemplateRows: 'auto auto',
        }}>
            {/* First Row */}
            <div style={{ gridColumn: '1 / -1', display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'center' }}>
                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Bold"><Button onClick={() => editor.chain().focus().toggleBold().run()}><FormatBold /></Button></Tooltip>
                    <Tooltip title="Italic"><Button onClick={() => editor.chain().focus().toggleItalic().run()}><FormatItalic /></Button></Tooltip>
                    <Tooltip title="Underline"><Button onClick={() => editor.chain().focus().toggleUnderline().run()}><FormatUnderlined /></Button></Tooltip>
                    <Tooltip title="Strikethrough"><Button onClick={() => editor.chain().focus().toggleStrike().run()}><StrikethroughS /></Button></Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Heading 1"><Button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}><Title /> H1</Button></Tooltip>
                    <Tooltip title="Heading 2"><Button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}><Title /> H2</Button></Tooltip>
                    <Tooltip title="Heading 3"><Button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}><Title /> H3</Button></Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Bullet List"><Button onClick={() => editor.chain().focus().toggleBulletList().run()}><FormatListBulleted /></Button></Tooltip>
                    <Tooltip title="Numbered List"><Button onClick={() => editor.chain().focus().toggleOrderedList().run()}><FormatListNumbered /></Button></Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Blockquote"><Button onClick={() => editor.chain().focus().toggleBlockquote().run()}><FormatQuote /></Button></Tooltip>
                    <Tooltip title="Code Block"><Button onClick={() => editor.chain().focus().toggleCodeBlock().run()}><Code /></Button></Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Horizontal Rule"><Button onClick={() => editor.chain().focus().setHorizontalRule().run()}><HorizontalRule /></Button></Tooltip>
                    <Tooltip title="Remove Format"><Button onClick={() => editor.chain().focus().unsetAllMarks().run()}><FormatClear /></Button></Tooltip>
                </ButtonGroup>
            </div>

            {/* Second Row */}
            <div style={{ gridColumn: '1 / -1', display: 'flex', flexWrap: 'wrap', gap: '8px', justifyContent: 'center' }}>
                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Align Left"><Button onClick={() => editor.chain().focus().setTextAlign('left').run()}><FormatAlignLeft /></Button></Tooltip>
                    <Tooltip title="Align Center"><Button onClick={() => editor.chain().focus().setTextAlign('center').run()}><FormatAlignCenter /></Button></Tooltip>
                    <Tooltip title="Align Right"><Button onClick={() => editor.chain().focus().setTextAlign('right').run()}><FormatAlignRight /></Button></Tooltip>
                    <Tooltip title="Justify"><Button onClick={() => editor.chain().focus().setTextAlign('justify').run()}><FormatAlignJustify /></Button></Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Link"><Button onClick={() => {
                        const url = window.prompt('Enter URL');
                        if (url) {
                            editor.chain().focus().setLink({ href: url }).run();
                        }
                    }}><Link /></Button></Tooltip>
                    <Tooltip title="Image"><Button onClick={() => {
                        const imageUrl = window.prompt('Enter Image URL');
                        if (imageUrl) {
                            editor.chain().focus().setImage({ src: imageUrl }).run();
                        }
                    }}><Image /></Button></Tooltip>
                </ButtonGroup>

                <ButtonGroup variant="outlined" size="small">
                    <Tooltip title="Text Color"><Button onClick={() => {
                        const color = window.prompt('Enter Color (e.g., red, #FF0000)');
                        if (color) {
                            editor.chain().focus().setColor(color).run();
                        }
                    }}><FormatColorText /></Button></Tooltip>
                    <Tooltip title="Subscript"><Button onClick={() => editor.chain().focus().toggleSubscript().run()}><Subscript /></Button></Tooltip>
                    <Tooltip title="Superscript"><Button onClick={() => editor.chain().focus().toggleSuperscript().run()}><Superscript /></Button></Tooltip>
                </ButtonGroup>
            </div>
        </div>
    );
};

export default Toolbar;