import '../../css/legal.css'

export default function CSAE() {
    return (
        <div className='container'>
            <div className='starting_section'>
                <h1 className='heading'>
                    Child Safety Abuse and Exploitation Standards
                </h1>
                <h3 className='sub_heading'>
                    Last Modified: 4th February 2025
                </h3>

            </div>
            <hr></hr>
            <p>
                At Muslims Just Marry (MJMarry), we are committed to maintaining the highest safety
                standards to protect our users from any form of Child Sexual Abuse and Exploitation (CSAE).
                Our platform strictly adheres to global legal frameworks, including but not limited to the United Nations Convention on the Rights of the Child (UNCRC), The Palermo Protocol, The U.S. PROTECT Act,
                The UK Online Safety Act, and other relevant international and regional child safety laws.

                <br></br><br></br><br></br>
                If you have any questions regarding our child safety standard, please check the end of this CSAE Standards for contact details. We encourage you to read this CSAE Standard alongside our Privacy Policy and Terms of Service.
            </p>
            <div className='privacy terms cookies'>
                <h1>Our Commitment to CSAE Safety</h1>
                <h2>1. Age Verification and Strict Eligibility Criteria</h2>
                <ul>
                    <li>
                        <p>

                            MJMarry is exclusively for adults (18+ years old) seeking marriage.
                        </p>
                    </li>
                    <li>
                        <p>
                            We use manual identity/photo verification to confirm users’ ages and prevent minors from accessing the platform.
                        </p>
                    </li>
                    <li>
                        <p> Any attempt to falsify age or provide misleading information results in permanent account suspension and reporting to relevant authorities.
                        </p>
                    </li>
                </ul>
                <h2>2. Zero Tolerance for CSAE Content & Activity </h2>
                <ul>
                    <li>
                        <p>
                            Any content, conversation, or behavior related to child exploitation, abuse, grooming, or inappropriate interactions with minors is strictly prohibited.
                        </p>
                    </li>
                    <li>
                        <p>
                            We employ real-time monitoring, and manual review to detect and prevent any CSAE-related activities.
                        </p>
                    </li>
                    <li>
                        <p>
                            We immediately report any detected CSAE cases to global law enforcement agencies, including Interpol, NCMEC (National Center for Missing & Exploited Children), and local authorities.
                        </p>
                    </li>
                </ul>
                <h2>3. Proactive Report and Moderation</h2>
                <ul>
                    <li>
                        <p>
                            We have an easy-to-use in-app reporting system for users to flag suspicious activity.
                        </p>
                    </li>
                    <li>
                        <p>
                            Reports are reviewed within 24 hours, and immediate action is taken if CSAE-related activity is detected.
                        </p>
                    </li>
                    <li>
                        <p>
                            We cooperate fully with law enforcement, providing necessary evidence to aid investigations.
                        </p>
                    </li>
                </ul>
                <h2>4. Human Moderation</h2>
                <ul>
                    <li>
                        <p>
                            A dedicated safety team manually reviews flagged content and user activity to ensure swift intervention.
                        </p>
                    </li>
                    <li>
                        <p>
                            Our system is updated regularly to comply with the latest child protection laws and best practices.
                        </p>
                    </li>
                </ul>
                <h2>5. Secure Communication and Data Protection</h2>
                <ul>
                    <li>
                        <p>
                            All user interactions are end-to-end encrypted to prevent unauthorized access and misuse.
                        </p>
                    </li>
                    <li>
                        <p>
                            Personal data is handled in compliance with GDPR, CCPA, and other data protection regulations.
                        </p>
                    </li>
                    <li>
                        <p>
                            We do not store, share, or distribute any sensitive user data that could be exploited.
                        </p>
                    </li>
                </ul>
                <h2>6. Collaboration with Global Authorities & Organizations</h2>
                <ul>
                    <li>
                        <p>
                        MJMarry works with law enforcement agencies, child protection NGOs, and cybersecurity experts to ensure a safe online environment.
                        </p>
                    </li>
                    <li>
                        <p>
We actively contribute to international efforts to combat online CSAE and report offenders to relevant legal bodies.
                        </p>
                    </li>
                    <li>
                        <p>
                        We engage in continuous staff training on CSAE prevention and digital safety standards.
                        </p>
                    </li>
                </ul>
                <h2>7. User Awareness</h2>
                <ul>
                    <li>
                        <p>
                        Our safety page includes tips on identifying suspicious behavior and instructions on reporting violations.
                        </p>
                    </li>
                    <li>
                        <p>
                        We encourage a community-driven safety approach, where users actively participate in keeping the platform secure.
                        </p>
                    </li>
                </ul>
                <h1>Enforcement & Consequences</h1>
                <ul>
                    <li>
                        <p>
                        Any user violating these safety standards faces immediate account termination.
                        </p>
                    </li>
                    <li>
                        <p>
                        Offenders are reported to law enforcement and permanently banned from our platform.
                        </p>
                    </li>
                    <li>
                        <p>
                        We maintain a blacklist of CSAE offenders, ensuring they cannot rejoin the platform under a different identity. 
                        </p>
                    </li>
                </ul>
               <p> At Muslims Just Marry (MJMarry), protecting our community is our highest priority. We continuously enhance our security measures to ensure a safe, respectful, and lawful environment 
                for all users. If you suspect any CSAE-related activity, please report it immediately through our in-app reporting system or contact our safety team at support@mjmarry.com.</p>
            </div>
        </div>
    )
};