import '../css/doc.css';
import CSAE from './dynamic/CSAE'


const CSAEComponent = () => {
  return (
    <>
      <div className="container mt-5 content-center">
        <hr></hr>
        <CSAE />
        <hr></hr>
      </div>
    </>
  );
};

export default CSAEComponent;
