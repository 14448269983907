import axios from "axios";
import { SERVER } from "../../Constants";

const apiClient = axios.create({
    baseURL: SERVER + "/posts",
    withCredentials: true,
});

// Create a new banner post
export const createBanner = async (bannerData) => {
    try {
        const response = await apiClient.post("/post", bannerData);
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to create banner");
    }
};

// Edit an existing banner
export const editBanner = async (id, updates) => {
    try {
        const response = await apiClient.put(`/edit/${id}`, updates);
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to update banner");
    }
};

// Delete a banner
export const deleteBanner = async (id) => {
    try {
        const response = await apiClient.delete(`/delete/${id}`);
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to delete banner");
    }
};

// Activate a banner
export const activateBanner = async (id) => {
    try {
        const response = await apiClient.put(`/activate/${id}`);
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to activate banner");
    }
};

// Inactivate a banner
export const inactivateBanner = async (id) => {
    try {
        const response = await apiClient.put(`/inactivate/${id}`);
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to inactivate banner");
    }
};

// Get active active banners
export const getActiveBanners = async () => {
    try {
        const response = await apiClient.get("/active");
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to fetch active banners");
    }
};

export const getAllBanners = async () => {
    try {
        const response = await apiClient.get("/all");
        return response.data;
    } catch (error) {
        console.error("API error:", error.response);
        throw new Error(error.response?.data?.error || "Failed to fetch active banners");
    }
};
