import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/home/HomeComponent";
import NavBar from "./navbar/Navbar";
import "./css/bootstrap/bootstrap.css";
import ProtectedRoute from "./ProtectedRoute";
import { Footer } from "./pages/Footer";
import HelpComponent from "./pages/HelpComponent";
import FAQComponent from "./pages/FAQComponent";
import GoldComponent from "./pages/GoldComponent";
import SafetyComponent from "./pages/SafetyComponent";
import PrivacyComponent from "./pages/PrivacyComponent";
import ContactUsComponent from "./pages/ContactUsComponent";
import BehaviorComponent from "./pages/BehaviourComponent";
import CSAEComponent from "./pages/CSAEComponent";
import CookiesComponent from "./pages/dynamic/Cookies";
import TermsAndConditionsComponent from "./pages/TermsAndConditionsComponent";
import { useEffect } from "react";
import LoginComponent from "./pages/LoginComponent";
import { AuthProvider } from "./context/AuthContext";
import { AdminNav } from "./pages/dashboard/navbar/SideNavbar";
import { ThemeProvider } from "@mui/material";
import { theme } from "./pages/Theme/theme";
import VerifyAccounts from "./pages/dashboard/VerifyAccount";
import Trends from "./pages/dashboard/AdminTrends";
import AllUsers from "./pages/dashboard/AllUsers";
import TCMs from "./pages/dynamic/TermsAndConditonsMobile";
import AdminProfile from "./pages/dashboard/AdminProfile";
import DisableAccountComponent from "./pages/dashboard/DisableAccComponent";
import { useTranslation } from 'react-i18next';
import Dashboard from "./pages/dashboard/Dashboard";
import PostComponent from "./pages/dashboard/PostComponent";

function App() {
  const {i18n} = useTranslation();
  useEffect(() => {
    document.title = "MJMarry";
  }, []);

  useEffect(() => {
    const storedLang = localStorage.getItem("i18nLanguage");
    if (storedLang) {
      i18n.changeLanguage(storedLang);
    }
  }, [i18n]);

  const location = useLocation();
  const isDashboard = location.pathname.startsWith("/dashboard");
  const isMobile = location.pathname === "/termsandconditionsmobile";

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <div
          className="App"
          style={{ overflowX: "hidden", minHeight: "100vh", background: "white" }}
        >
          {/* {isDashboard ? <NavBarAdmin/> : <NavBar />} */}
          {/* Show NavBarAdmin for dashboard, NavBar for others, but hide for /termsandconditionsmobile */}
          {!isDashboard && !isMobile ? <NavBar /> : isDashboard ? <AdminNav /> : null}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<HomePage />} />  
            <Route path="/help" element={<HelpComponent />} />
            <Route path="/gold" element={<GoldComponent />} />
            <Route path="/faq" element={<FAQComponent />} />
            <Route path="/safety" element={<SafetyComponent />} />
            <Route path="/privacy" element={<PrivacyComponent />} />
            <Route path="/contactus" element={<ContactUsComponent />} />
            <Route path="/behaviour" element={<BehaviorComponent />} />
            <Route path="/cookies" element={<CookiesComponent />} />
            <Route path="/csae" element={<CSAEComponent />} />



            <Route
              path="/termsandconditions"
              element={<TermsAndConditionsComponent />}
            />
            <Route
              path="/termsandconditionsmobile"
              element={<TCMs />}
            />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/dashboard" element={<ProtectedRoute />}>
              <Route exact path="/dashboard/trends" element={<Trends/>}/>
              <Route exact path="/dashboard/posts" element={<PostComponent/>}/>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/dashboard/verify-accounts" element={<VerifyAccounts />} />
              <Route exact path="/dashboard/all-users" element={<AllUsers />} />
              <Route exact path="/dashboard/profile" element={<AdminProfile />} />
              <Route path="/dashboard/user/disableacc" element={<DisableAccountComponent />} />

            </Route>
          </Routes>
          {!isDashboard && !isMobile ? <Footer /> : null}
        </div>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
