import React, {useState, useEffect} from 'react';
import {getActiveBanners, createBanner, editBanner, deleteBanner, getAllBanners} from '../../context/Post';
import PostList from './PostList';
import PostForm from './PostForm';

const PostComponent = () => {
    const [posts, setPosts] = useState([]);
    const [activeFilter, setActiveFilter] = useState('all');
    const [editingPost, setEditingPost] = useState(null);

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        const data = await getAllBanners();
        setPosts(data.banners);
    };

    const handleCreatePost = async (postData) => {
        await createBanner(postData);
        fetchPosts();
    };

    const handleEditPost = async (postData) => {
        await editBanner(editingPost._id, postData);
        fetchPosts();
        setEditingPost(null);
    };

    const handleDeletePost = async (id) => {
        await deleteBanner(id);
        fetchPosts();
    };

    return (
        <div style={{
            maxWidth: '800px',
            margin: '100px 100px 100px 350px',
            padding: '20px',
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)'
        }}>
            <button onClick={() => setEditingPost({})} style={{
                padding: '10px',
                marginBottom: '10px',
                background: '#007bff',
                color: 'white',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer'
            }}>
                Create New Post
            </button>

            {editingPost && <PostForm post={editingPost} onSave={handleCreatePost} onEdit={handleEditPost}
                                      onCancel={() => setEditingPost(null)}/>}

            <PostList posts={posts} filter={activeFilter} onEdit={setEditingPost} onDelete={handleDeletePost}
                      setFilter={setActiveFilter}/>
        </div>
    );
};

export default PostComponent;
