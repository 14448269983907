import React, {useState} from 'react';
import {useEditor, EditorContent} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Heading from '@tiptap/extension-heading';
import Blockquote from '@tiptap/extension-blockquote';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import CodeBlock from '@tiptap/extension-code-block';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Strike from '@tiptap/extension-strike';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';

import Toolbar from './Toolbar';

const PostForm = ({post, onSave, onEdit, onCancel}) => {
    const [title, setTitle] = useState(post?.title || '');
    const [content, setContent] = useState(post?.content || '<p>Start writing...</p>');
    const [isActive, setIsActive] = useState(post?.status === "active"); // Using checkbox for status

    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Heading.configure({levels: [1, 2, 3, 4, 5, 6]}),
            Blockquote,
            BulletList,
            OrderedList,
            ListItem,
            CodeBlock,
            Link.configure({openOnClick: false}),
            Image,
            TextAlign.configure({types: ['heading', 'paragraph']}),
            Strike,
            Color,
            TextStyle,
            Subscript,
            Superscript
        ],
        content,
        onUpdate: ({editor}) => setContent(editor.getHTML()),
    });

    const handleSubmit = () => {
        const updatedPost = {
            ...post,
            title,
            content,
            status: isActive ? "active" : "inactive",
        };
        console.log("To submit: ", updatedPost)

        if (post?._id) {
            onEdit(updatedPost);
        } else {
            onSave(updatedPost);
        }
    };

    return (
        <div style={{
            padding: '15px',
            border: '1px solid #ddd',
            borderRadius: '6px',
            background: '#f9f9f9',
            marginBottom: '10px',
        }}>
            {/* Title Input */}
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Post Title"
                style={{
                    width: '100%',
                    padding: '12px',
                    marginBottom: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    fontSize: '16px',
                    outline: 'none',
                }}
            />

            {/* Status Checkbox */}
            <label style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    style={{marginRight: '8px'}}
                />
                <span style={{fontSize: '14px', fontWeight: 'bold', color: isActive ? '#28a745' : '#dc3545'}}>
                    {isActive ? 'Active' : 'Inactive'}
                </span>
            </label>

            {/* Toolbar for the editor */}
            <Toolbar editor={editor}/>

            {/* Editor with full TipTap extensions */}
            <div style={{
                minHeight: '250px',
                padding: '15px',
                border: '1px solid #ddd',
                borderRadius: '6px',
                boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
                background: '#fafafa',
                fontSize: '16px',
                lineHeight: '1.6',
                marginBottom: '10px'
            }}>
                <EditorContent editor={editor}/>
            </div>

            {/* Save & Cancel Buttons */}
            <button
                onClick={handleSubmit}
                style={{
                    padding: '10px',
                    background: '#007bff',
                    color: 'white',
                    borderRadius: '5px',
                    border: 'none',
                    cursor: 'pointer',
                    marginRight: '10px',
                }}
            >
                Save Post
            </button>

            <button
                onClick={onCancel}
                style={{
                    padding: '10px',
                    background: '#6c757d',
                    color: 'white',
                    borderRadius: '5px',
                    border: 'none',
                    cursor: 'pointer',
                }}
            >
                Cancel
            </button>
        </div>
    );
};

export default PostForm;
