import React from 'react';

const PostItem = ({ post, onEdit, onDelete }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            background: '#f8f9fa',
            height: '100vh',
        }}>
            <div style={{
                width: '400px',
                height: '600px', // Adjusted height
                border: '10px solid #000',
                borderRadius: '30px',
                padding: '12px',
                background: '#fff',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {/* Top Notch */}
                <div style={{
                    width: '100px',
                    height: '5px',
                    background: '#bbb',
                    borderRadius: '10px',
                    position: 'absolute',
                    top: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>

                {/* Scrollable Content */}
                <div style={{
                    flex: 1,
                    overflowY: 'auto',
                    paddingRight: '10px', // Avoid scrollbar overlap
                }}>
                    <h3 style={{ fontSize: '16px', marginBottom: '5px' }}>{post.title}</h3>

                    <div style={{
                        fontSize: '14px',
                        color: '#444',
                        wordBreak: 'break-word',
                        maxWidth: '100%',
                    }}>
                        <p dangerouslySetInnerHTML={{ __html: post.content }} />
                    </div>

                    <p style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: post.isActive ? '#28a745' : '#dc3545',
                        marginTop: '5px'
                    }}>
                        Status: {post.isActive ? 'Active' : 'Inactive'}
                    </p>
                </div>

                {/* Buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <button
                        onClick={() => onEdit(post)}
                        style={{
                            flex: 1,
                            padding: '8px',
                            background: '#ffc107',
                            borderRadius: '6px',
                            border: 'none',
                            fontSize: '12px',
                            cursor: 'pointer',
                            marginRight: '5px'
                        }}
                    >
                        Edit
                    </button>

                    <button
                        onClick={() => onDelete(post._id)}
                        style={{
                            flex: 1,
                            padding: '8px',
                            background: '#dc3545',
                            color: 'white',
                            borderRadius: '6px',
                            border: 'none',
                            fontSize: '12px',
                            cursor: 'pointer'
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PostItem;
