import React from 'react';
import PostItem from './PostItem';

const PostList = ({ posts, filter, onEdit, onDelete, setFilter }) => {
    const filteredPosts = filter === 'all' ? posts : posts.filter(post => post.status==="filter");
    console.log("filtered posts: ", filteredPosts)
    return (
        <div style={{ padding: '20px', background: '#f8f9fa', minHeight: '100vh' }}>
            {/* Filter Buttons */}
            <div style={{ marginBottom: '15px', textAlign: 'center' }}>
                <button onClick={() => setFilter('all')} style={buttonStyle}>All</button>
                <button onClick={() => setFilter('active')} style={buttonStyle}>Active</button>
                <button onClick={() => setFilter('inactive')} style={buttonStyle}>Inactive</button>
            </div>

            {/* Posts Grid */}
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))',
                gap: '15px',
                justifyContent: 'center',
            }}>
                {filteredPosts.map(post => (
                    <PostItem key={post.id} post={post} onEdit={onEdit} onDelete={onDelete} />
                ))}
            </div>
        </div>
    );
};

const buttonStyle = {
    padding: '8px 15px',
    margin: '5px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    background: '#007bff',
    color: '#fff',
    fontSize: '14px'
};

export default PostList;
